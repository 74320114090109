// AboutPage.js
import React from 'react';
import { Box, Container, Grid, Typography,Button ,Card, CardContent, CardMedia,IconButton} from '@mui/material';
import Aboutimg from './assets/image 9.png'
import teamMembers from './Data/teamMembers.json';
import dheerajImg from './Data/Dheeraj-Jhawar.jpg'
import sandeepimg from './Data/Sandeep-Agarwal.jpg'
import Dineshimg from './Data/Dinesh-Kunder.jpg'
import Siddeshimg from './Data/Siddesh Rai.jpg'

import Abhishekimg from './Data/Abhishek Jhawar.jpg'
import Komalimg from './Data/Komal Agarwal.jpg'
import Nirmalimg from './Data/Nirmal Bora.jpg'
import Riddhiimg from './Data/Riddhi Vasoliya.jpg'
import Ajinkyaimg from './Data/Ajinkya Shewale.jpg'
import Karthikimg from './Data/Karthik Valathi.jpg'
import Reshmaimg from './Data/Reshma Shetty.jpg'


import LinkedInIcon from './assets/Linkedin.png';
const AboutPage = () => {
  const imageMap = {
    'Dheeraj Jhawar': dheerajImg,
    'Sandeep Agarwal': sandeepimg,
    'Dinesh kunder': Dineshimg,
    'Siddesh Rai': Siddeshimg,
    'Abhishek Jhawar': Abhishekimg,
    'Komal Agarwal': Komalimg,
    'Nirmal Bora': Nirmalimg,
    'Riddhi Vasoliya': Riddhiimg,
    'Ajinkya Shewale': Ajinkyaimg,
    'Karthik Valathi': Karthikimg,
    'Reshma Shetty': Reshmaimg,
    // ... more mappings
  };

const TeamCard = ({ name, position, Digari,linkedID, description,description1,description2,index  }) => (
  <Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 596, m: 1 ,height: index < 4 ? 530 : 220, borderRadius: '16px'}}>
  <Box sx={{ display: 'flex', flexDirection: 'row', p: 1 }}>
    <CardMedia
      component="img"
      sx={{
        p:2,
        height: 175, // Set the height
        width: 150, // Set the width
        objectFit: 'contain', // This will ensure the image is fully visible
        
      }}
      image={imageMap[name]}
      alt={`Photo of ${name}`}
    />
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: 1 }}>
      <Typography gutterBottom variant="h5" font='Roboto' component="div"  sx={{ color: '#006956' ,marginBottom:'0px'  }}>
        {name}
      </Typography>
      <Typography variant="h6" font='Roboto' sx={{ color: '#6C6C6C' ,marginBottom:'0px'  }}>
        {position}
      </Typography>
      <Typography variant="body2" font='Roboto' color="text.secondary">
        {Digari}
      </Typography>
      <IconButton href={linkedID} target="_blank"  sx={{ marginLeft:'-50%' }}>
         <img src={LinkedInIcon}  width={'35%'} /> 
      </IconButton>
    </Box>
  </Box>
  <CardContent sx={{ pt: 0 ,pl:3.5 }}>
    <Typography variant="body2" color="text.secondary"  font='Roboto'>
      {description}
    </Typography>
    <Typography variant="body2" color="text.secondary" font='Roboto' sx={{mt:'2%'}}>
      {description1}
    </Typography>
    <Typography variant="body2" color="text.secondary" font='Roboto' sx={{mt:'2%'}}>
      {description2}
    </Typography>
  </CardContent>
</Card>
);

  return (
    <Box  marginTop='120px'  >
      <Box height={350}  style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
          <img src={Aboutimg} alt="Image 4" width={'100%'}  height={'100%'}/> 
      </Box>
       <Box style={{backgroundColor:"#525252" ,height:'500px', marginTop:'0%'}}  >

          <Typography variant="h2" style={{ marginLeft:'12%',color:'#fff' ,font:'Roboto' ,padding:'2%'}} font={'Roboto'} fontWeight={50}  >Overview</Typography>
          <Typography variant="body1" style={{ marginLeft:'15%' ,color:'#fff' ,font:'Roboto',  width:'80%'}}  fontWeight={50}>
          Founded in 2015, Elements Financial Solutions Private Limited (EFS) stands as a beacon in the realm of global financial and business advisory. We go beyond traditional financial metrics, crafting strategies that create substantial value for businesses while positively influencing society. Our mission is to guide corporations through their growth trajectory by providing tailored financial advisory services that encapsulate both economic and social dimensions. 
        </Typography>

        <Typography variant="body1" style={{ marginLeft:'15%' ,color:'#fff' ,font:'Roboto' ,marginTop: '2%' , width:'80%'}}  fontWeight={50}>
           At EFS, we believe in the power of synergy and collaboration. We work closely with our clients to turn complex challenges into opportunities, promoting transformative changes that resonate on multiple levels—from local communities to global markets. Our comprehensive suite of services includes but is not limited to strategic planning, financial restructuring, economic analysis, and bespoke advisory tailored to the unique needs of each client. Our team of seasoned experts is committed to fostering sustainable business practices and helping our clients achieve their long-term objectives. We pride ourselves on our ability to navigate the intricacies of both local and international markets, ensuring our clients receive the most sophisticated and effective solutions tailored to their specific industry and market dynamics.
        </Typography>

        <Typography variant="body1" style={{ marginLeft:'15%' ,color:'#fff' ,font:'Roboto' ,marginTop: '2%' , width:'80%'}}  fontWeight={50}>
          Our team of seasoned experts is committed to fostering sustainable business practices and helping our clients achieve their long-term objectives. We pride ourselves on our ability to navigate the intricacies of both local and international markets, ensuring our clients receive the most sophisticated and effective solutions tailored to their specific industry and market dynamics.
        </Typography>
      </Box>
      <Box bgcolor="#E9E9E9" pt={5} pb={5}>
      <Container>
        <Typography style={{ color: '#006956', font: 'Roboto', mb: 4 }} variant="h2" gutterBottom>
          Our Team
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {teamMembers.map((member, index) => (
            <Grid item xs={12} md={6} key={index}>
              <TeamCard {...member} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
    </Box>
  );
};

export default AboutPage;
